<template>
	<div class="container">
		<div class="lang">
			<el-select v-model="value" placeholder="please choose" @change="clickChange">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
			</el-select>
		</div>
		<h1>Whistle Privacy and Data Usage Policy</h1>
		<p>Privacy and data usage policies are part of Whistle's corporate mission and are the basis for giving members a more open and intimate understanding of Whistle and the use of the Whistle platform. This policy explains what information we collect, how it is used and shared. You can learn about additional tools and information at Privacy Basics. </p>
		<p>When you review our policies, please remember that these policies apply to all Whistle platform brands, products, and services that do not have independent privacy policies or are related to this policy. We refer to them as "Whistle Services" or " Serve". </p>
		<h2>Article 1 What information do we collect? </h2>
		<p>We will collect different information from or about you depending on the services you use. </p>
		<p>1. The operations you perform and the information you provide. </p>
		<p>
		We collect content and other information you provide when you use our Services, including when you register for an account, create or share messages, and message or communicate with others. This includes content information you provide or information about you, such as the location of a photo or the date a file was created. We also collect information about how you use our Services, such as the type of content you view or engage with or the frequency and duration of such activity.
		</p>
		<p>2. Operations performed by others and information provided. </p>
		<p>We also collect content and information that others provide when they use our Services, which includes information about you, such as when they share a photo of you, send you a message, or upload, sync or import your contacts Information provided at the time of the information. </p>
		<p>3. Your personal network and relationship network. </p>
		<p>We collect information about your contacts and groups you are a member of, as well as information about your level of interaction with them, such as the individuals you communicate with the most or the groups you like to share with. We also collect contact information (such as address books) that you upload, sync, or import from your device. </p>
		<p>4. Payment information. </p>
		<p>
		If you use our Services to make a purchase or financial transaction (for example, if you shop on Whistle, make an in-game purchase, or make a donation), we collect information about those purchases or transactions. This includes your payment information, such as your credit or debit card number and other card information, other account and authentication information, billing, shipping and contact details.
		</p>
		<p>5. Equipment information. </p>
		<p>
		We will also collect information from or about the computers, mobile phones or other devices on which you install or access our Services, based on the permissions you grant. We may associate information collected from your various devices so that we can provide you with consistent services across these devices. Here are some examples of device information we collect:
		1) Properties such as operating system, hardware version, device settings, file and software names and types, battery and signal strength, and device identifiers.
		2) Device location, including specific geographic location, such as location information obtained through GPS, Bluetooth or WiFi signals.
		3) Connection information, such as your mobile carrier or ISP name, browser type, language and time zone, mobile phone number and IP address.
		4) Information from websites and applications that use our services. We collect information when you visit or use third-party websites and apps that use our Services, such as when they offer our Like button or Whistle Login or use our measurement services and advertising services. The information collected includes information about the websites and apps you visit, our services you use on those websites and apps, and information provided to you or us by app or website developers or publishers.
		5) Information from third-party partners. We receive information about you and your activities while using and not using Whistle from third-party partners, such as information we receive from a partner when we work with that partner to provide services, or information we receive about you from an advertiser. experiences or information you interact with.
		6) Whistle’s companies. For companies owned or operated by Whistle, we will receive information about you from them in accordance with their relevant terms and policies. Learn more about these companies and their privacy policies.
		</p>
		<h2>Article 2 How do we use this information? </h2>
		<p>We are passionate about creating engaging and tailored experiences for our users. We use any information we receive to help us provide and support our services. Specific operations:</p>
		<p>1) Provide, improve and develop services. Using this information, we can understand how you use and interact with our services, and how you interact with people and things you are connected to and interested in during and without using Whistle services, so that we can provide you with services. , personalized content, and recommendations. </p>
		<p>2) We also use this information to provide you with shortcuts and suggestions. For example, we can suggest that your friends tag you in their photos by comparing their photos to the photos we have collected in which you have been tagged. If this feature is turned on for you, you can use the Timeline & Tags settings to control whether we allow us to suggest that other users tag you in photos. </p>
		<p>3) After obtaining location information, we will use this information to tailor services to you and others, for example, to help you check in and find local events or offers in your area, or to tell friends that you are nearby. </p>
		<p>4) We conduct surveys and research, test features under development and analyze the information we have in order to evaluate and improve products and services, develop new products or features and conduct auditing and troubleshooting activities. </p>
		<p>5) Communicate with you. We use your information to send you marketing communications, communicate with you about our services, and make you aware of our policies and terms. We also use your information to respond to you when you contact us. </p>
		<p>6) Display and measure ads and services. We use the information we have to improve our advertising and measurement systems so that we can display relevant advertising to you both when using and not using the Whistle Service and to measure the effectiveness and reach of our advertising and services. Learn more about advertising on our online services and learn how to control how your information is used to personalize the advertising you see. </p>
		<p>7) Improve safety and security. We use the information we have to help verify accounts and activity, improve your security both on and off the Whistle Service, such as to investigate suspicious activity or violations of our terms and policies. We work hard to keep your account secure through a team of engineers, automated systems, and advanced technologies like encryption and machine learning. We also offer easy-to-use security tools to add an extra layer of security to your account. For more information about improving Whistle security, visit the Whistle Security (Help) Center. We use cookies and similar technologies to provide and support our services, and the use of these technologies is listed and described in this section of our policy. Read our Cookie Policy to learn more. </p>
		<h2>Article 3 How will this information be shared? </h2>
		<p>Sharing on our Services Users connect and share with each other through our Services. We share your information in the following ways to achieve this purpose:</p>
		<p>1. Users you share and communicate with. When you share and communicate through our Services, you choose the audience who can see what you share. For example, when you post on Whistle, you choose the audience for the post, such as a custom personal group, all your friends, or members of a group. </p>
		<p>2. Public information refers to any information you share publicly, including information in public files, or content shared on the Whistle homepage or other public forums. Public information is available to anyone through our online and offline services and can be viewed or accessed through online search engines, APIs, and offline media (such as television). </p>
		<p>3. In some cases, the people you share and communicate with may download the information, or share the information with others again through our services and other services. When you comment on someone's post or like something they share on Whistle, that person decides which audience can see your comment or like. If their selected audience is public, your comment will be public as well. </p>
		<p>4. Users who see other people sharing information about you. Others may use our Services to share information about you with audiences of their choosing. For example, these users may share a photo of you, mention or tag you in a post, or share information that you shared with them. If you have concerns about a user's post, Social Reports can help you quickly and easily get help from people you trust. Learn more. </p>
		<p>5. Third-party applications and websites that integrate or use our services. For third-party applications, websites or other services that use or integrate our services, they may receive information you post or share when you use them. For example, when you play a game with Whistle friends on the website or use Whistle's "Comment" or "Share" buttons, the game developer or related website may obtain information about your activity in the game or receive information you post. Comment or share a link to Whistle from their website. Additionally, when you download or use such third-party services, they have access to your public profile, which includes your username or user ID, age group and country/language, friends list, and any information you share with them. Information collected by these applications, websites or integrated services is subject to their own terms and policies. Learn more about how you can control the information about you or others that is shared through these apps and websites. </p>
		<p>6. Share with Whistle-owned companies. We share information we have about you with the Whistle family of companies. Learn more about Whistle's companies. </p>
		<p>7. New owner. If there is a change of ownership or control of all or part of our services or assets, we may transfer your information to the new owners. </p>
		<p>8. Sharing with third-party partners and customers We cooperate with third-party companies that help us provide and improve services or use advertising or related products so that we can operate the company and provide free services to users around the world. The following are the types of third parties with which we share information about you:</p>
		<p>
		1) Advertising, measurement and analytics services (non-personally identifiable information only). We want our ads to be as relevant and interesting as the other content on our Service. With this in mind, we use the information we have to show you relevant advertising. We will not use your personally identifiable information (information that identifies you, such as your name or email address, by which you can be contacted or identified) to provide advertising, measurement and analytics unless we have your permission. Service partners share. We provide these partners with information about the reach and effectiveness of their advertising without providing your personally identifiable information, or we aggregate the information so that it does not identify you personally. For example, we may tell advertisers how effective their ads are, or how many people saw their ads or installed apps after seeing them, or provide information to these advertisers only if they agree to abide by our advertising guidelines. Partners provide non-personally identifiable demographic information to help them understand their audience or customers.
		2) Suppliers, service providers and other partners. We send information to suppliers, service providers and other partners who support our business in China. This support includes providing technical infrastructure services, analyzing how our services are used, measuring the effectiveness of advertising and services, and providing customer service. , facilitate payments or conduct academic research and investigations. These partners are subject to strict confidentiality obligations in accordance with this Data Policy and our agreements with them.
		</p>
		<h2>Article 4 How to manage or delete information about me? </h2>
		<p>You can use the activity log to manage the content and information you share while using Whistle. You can also download information related to your Whistle through the Information Download Tool. We will retain the data until such time as it is no longer necessary to provide products and services to you and others (including the users described above). Information associated with your account will be retained until your account is deleted, unless we no longer need the data to provide products and services. </p>
		<p>You can delete your account at any time. When you delete your account, we will delete the content you posted, such as photos and status updates. If you don't want to delete your account, but would like to temporarily stop using Whistle, you can deactivate your account. To learn more about deactivating or deleting your account, click here. Remember, information shared about you by others is not part of your account information, so it won't be deleted when you delete your account. </p>
		<h2>Article 5 How do we respond to legal requests or prevent harm? </h2>
		<p>We may access, retain and share your information in response to a legal request (such as a search warrant, court order or subpoena) if we have good faith belief that the law requires us to do so. This may include legal requirements from jurisdictions outside of China, as long as we are satisfied that the response is required by law in that jurisdiction, affects users in that jurisdiction and complies with internationally recognized standards. We may also access, preserve and share information where we have good faith belief that it is necessary to: detect, prevent and respond to fraud and other illegal conduct, to protect ourselves, you and others (as part of an investigation); To prevent death or serious personal injury. For example, we may provide information about the reliability of your account to third-party partners to prevent fraud and abuse while you are using and not using the Whistle Services. If the information we receive about you includes financial transactions related to purchases made using Whistle,Data may need to be accessed, processed and retained for an extended period of time due to legal requirements or obligations, government investigations, investigations involving possible violations of our terms or policies, or to prevent harm. We will also retain account information that has been disabled due to a violation of our Terms for at least one year to prevent repeated violations or violations of our Terms. Whistle may share information within its companies or with third parties to achieve the purposes described in this policy. </p>
		<h2>Article 6 How will we notify you of changes to this policy? </h2>
		<p>We will notify you before revising this policy and allow you to review and comment on the revised policy before continuing to use our services. For more information about how Whistle handles privacy, please visit the Website Legal Notice, Privacy Statement, Trademark Notice, and Terms of Service. </p>
		<p>Last revision date: December 15, 2023</p>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				options: [
					{
						value: '中文',
						label: '中文'
					}, {
						value: 'English',
						label: 'English'
					}, {
						value: '한국인',
						label: '한국인'
					}, {
						value: '日本語',
						label: '日本語'
					}
				],
				value: '',
				language: ''
			}
		},
		created() {
			// url获取参数
			this.language = this.$route.query.language;
			if (this.language == 'zh_CN') {
				this.$router.push({
					path: '/PrivacyPolicyZh'
				})
			}
			if (this.language == 'en_US') {
				this.$router.push({
					path: '/PrivacyPolicyEn'
				})
			}
		},
		methods: {
			clickChange(value) {
				this.value = value;
				if (value == '中文') {
					this.$router.push({
						path: '/PrivacyPolicyZh'
					})
				}
				if (value == 'English') {
					this.$router.push({
						path: '/PrivacyPolicyEn'
					})
				}
				if (value == '한국인') {
					this.$router.push({
						path: '/PrivacyPolicyKo'
					})
				}
				if (value == '日本語') {
					this.$router.push({
						path: '/PrivacyPolicyJa'
					})
				}
			},
		}
	}
</script>

<style>
	.container {
		padding: 0.1rem 0.3rem;		
	}
	
	.lang {
		text-align: right;
		margin-bottom: 0.3rem;
	}

	h1,
	h2 {
		color: #333;
		font-size: 0.5rem;
	}

	h2 {
		margin-top: 20px;
		font-size: 0.4rem;
	}

	p {
		margin-bottom: 15px;
		font-size: 0.3rem;
	}
</style>